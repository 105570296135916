import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo, useState } from 'react'

export interface Props {
  image?: ImageProps
  title?: string
  subtitle?: string
  promo?: string
  index?: number
  className?: string
}

export const Item = memo(function Item({
  image,
  title,
  subtitle,
  promo,
  index,
  className,
}: Props) {
  return (
    <Container className={`${className}`}>
      <Wrapper>
        <WrapperImage>
          <StyledImage {...image} />
        </WrapperImage>
        <WrapperInfos>
          <Infos>
            {title ? (
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            ) : null}
            {subtitle ? (
              <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            ) : null}
            {promo ? (
              <Promo>
                <img src="/icon_promo.svg" alt="promo" />
                {promo}
              </Promo>
            ) : null}
            <Buttons>
              <StyledButton label="book now" variant="full-color-bg" />
              <StyledButton label="richiedi" variant="outline-dark" />
            </Buttons>
          </Infos>
        </WrapperInfos>
      </Wrapper>
    </Container>
  )
})

const Container = styled.article`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    display: block;
  }
`

const WrapperImage = styled.div`
  width: 29%;

  @media (max-width: 991px) {
    display: none;
  }
`
const StyledImage = styled(Image)`
  display: block;
  height: 100%;

  img {
    height: 100%;
    object-fit: cover;
    display: block;
  }
`

const WrapperInfos = styled.div`
  width: 71%;
  display: flex;
  padding: 3.33vw;
  align-items: center;

  @media (max-width: 991px) {
    padding: 20px;
    width: 100%;
  }
`
const Infos = styled.div`
  width: 100%;
`
const Title = styled.h3`
  font-size: 1.4vw;
  margin-bottom: 0.8vw;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 20px;
  }
`
const Subtitle = styled.h4`
  font-weight: 300;
  font-size: 1.2vw;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`
const Promo = styled.div`
  margin: 2vw 0 2vw 0;
  padding: 1vw 0;
  width: 100%;
  border-top: 1px dotted ${({ theme }) => theme.colors.variants.neutralLight1};
  border-bottom: 1px dotted
    ${({ theme }) => theme.colors.variants.neutralLight1};

  img {
    width: 20px;
    height: auto;
    margin-right: 20px;
  }

  @media (max-width: 991px) {
    margin: 20px 0;
    padding: 10px;

    img {
      margin-right: 10px;
    }
  }
`

const Buttons = styled.div`
  display: flex;

  @media (max-width: 991px) {
    gap: 10px;
    & > div {
      width: 50%;
    }
  }
`
const StyledButton = styled(Button)`
  margin-right: 1.5vw;

  &:last-child {
    margin-right: 0;
  }
`
