import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Item, Props as ItemProp } from './item'

export interface Props {
  dealPacks?: ItemProp[]
}

export const DealPacks = memo(function DealPacks({ dealPacks }: Props) {
  if (dealPacks && dealPacks.length <= 0) {
    return false
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderMain, instanceRefMain] = useKeenSlider({
    loop: true,
    initial: 0,
    slides: { perView: 2, spacing: 80 },
    defaultAnimation: {
      duration: 1500,
    },
    breakpoints: {
      '(max-width: 991px)': {
        slides: { origin: 'center', perView: 1.2, spacing: 5 },
        loop: false,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      let index = s.track.details.rel
    },
  })
  return (
    <Container>
      <FadeInUp>
        <WrapperTop>
          <Title dangerouslySetInnerHTML={{ __html: 'Pacchetti' }} />
        </WrapperTop>
      </FadeInUp>
      <FadeInUp>
        <SliderWrapper>
          <Slider ref={sliderMain} className="keen-slider">
            {dealPacks?.map((item, index) => (
              <Slide key={index} className="keen-slider__slide">
                <Item {...item} />
              </Slide>
            ))}
          </Slider>
          {dealPacks && dealPacks?.length > 2 ? (
            <>
              <Arrow
                className="arrow-left"
                onClick={(e) => {
                  instanceRefMain.current?.prev()
                }}
              />
              <Arrow
                className="arrow-right"
                direction="R"
                onClick={(e) => {
                  instanceRefMain.current?.next()
                }}
              />
            </>
          ) : null}
        </SliderWrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  padding: 0 6.66vw;
  margin: 6.66vw 0;

  @media (max-width: 991px) {
    padding: 30px 0;
    margin: 0;
  }
`

const WrapperTop = styled.div`
  padding: 3vw;
  border-top: dotted 2px ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 991px) {
    padding-top: 20px;
  }
`

const Title = styled.h3`
  font-size: 2.2vw;
  text-transform: uppercase;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const SliderWrapper = styled.div`
  position: relative;

  .arrow-left {
    top: 50%;
    left: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow-right {
    top: 50%;
    right: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(50%, -50%, 0);
  }

  @media (max-width: 991px) {
    .arrow-left,
    .arrow-right {
      display: none;
    }
  }
`
const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`
const Slide = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`
