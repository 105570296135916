import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  bookingButtonURL?: string
  contactsPageURL?: string
  languageCode: string
}

export const DealCTA = memo(function DealCTA({
  bookingButtonURL,
  contactsPageURL,
  languageCode,
}: Props) {
  return (
    <Container>
      <FadeInUp>
        <Wrapper>
          <Title
            dangerouslySetInnerHTML={{
              __html: useVocabularyData('deal-cta-label', languageCode),
            }}
          />
          <Buttons>
            {bookingButtonURL ? (
              <StyledButton
                label={useVocabularyData('book-now', languageCode)}
                target="_blank"
                URL={bookingButtonURL}
                variant="full-color-bg"
              />
            ) : null}

            {contactsPageURL ? (
              <StyledButton
                label={useVocabularyData('request', languageCode)}
                URL={`${contactsPageURL}#form`}
                variant="full-dark-bg"
              />
            ) : null}
          </Buttons>
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  padding: 0 6.66vw;
  margin: 3.33vw 0 6.66vw 0;

  @media (max-width: 991px) {
    padding: 30px 0;
    margin: 0;
  }
`

const Wrapper = styled.div`
  padding: 3vw;
  border-top: dotted 2px ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Title = styled.h3`
  font-size: 2.2vw;
  text-transform: uppercase;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 24px;
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5vw;

  @media (max-width: 991px) {
    margin-top: 30px;
  }
`
const StyledButton = styled(Button)`
  margin: 0 10px;
`
