import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

import { Item } from './item'

export interface Props {
  prevDeal?: any
  nextDeal?: any
}

export const DealsExtra = memo(function DealsExtra({
  prevDeal,
  nextDeal,
}: Props) {
  return (
    <Container>
      <FadeInUp>
        <Wrapper>
          <Item isPrevious={true} {...prevDeal} />
          <Item isPrevious={false} {...nextDeal} />
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 0px;
  }
`
